import React, {useState} from "react";
import TextField from '@material-ui/core/TextField';
import "../styles/loginstyle.css";
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import access from "../accesscode.js";

// a super duper simple token based login system.

// this could easily be adapted to a proper full size login

// system but for now we are just using an access code system


// super light-weight login system using shared context.

// VERY insecure. Anyone with half a brain can grab the password.


const Login = ({ setToken }) => {

	const [accessCode, setAccessCode] = useState();



	const handleSubmit = async e => {
		if(accessCode == access.accessCode){
		    setToken(access.token);
		}else
		{
			alert("Incorrect code! \nPlease contact the trial coordinator!");
		}
	}

	return(
			<div className="wrapper">

				

				<div className="login-main">

					<table>
						<tr>
							<td/>
							<td>
								<div className="blurbHolder">
									<h1 id="title">Nd.</h1>
								</div>
							</td>
							<td/>
						</tr>

						<tr>
							<td/>
							<td>
								<div className="blurbHolder">
									<h3> Welcome to NEUROdialect: A decision
										 augmentation and automated referral
										 system for screening, tracking, and
										 referring mental health disorders.
										 Compare and contrast shared
										 symptomatology of *ASD, OCD, IDD, and
										 ADHD* to construct a working
										 diagnosis and produce an automated
										 referral containing relevant clinical
										 terminology to access the desired
										 services. 
									</h3>

									<h6>
										*Autism Spectrum Disorder (ASD), Obsessive Compulsive Disorder
										(OCD), Intellectual Developmental Disorder (IDD), Attention
										Deficit Hyperactivity Disorder (ADHD).
									</h6>
								</div>
							</td>
							<td/>
						</tr>

						<tr>
							<td/>
							<td>
								<div id="submissionWindow">
									<h5 id="accessText"> Please enter the site access code </h5>
									<form className="loginForm" onSubmit={handleSubmit}>
										<TextField className="loginTextField" id="outlined-basic" onChange={e => setAccessCode(e.target.value)} label="Access Code" variant="outlined" />
										<Button  variant="secondary" type="submit" className="epic-button"> Confirm </Button>
									</form>
								</div>
							</td>
							<td/>
						</tr>

					</table>
				</div>
			</div>

		);
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}

export default Login;