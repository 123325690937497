import React, { Component, useState } from "react";
import Header from "./components/Header.js";
import { Modal, Button } from 'react-bootstrap';
import SelectionScreen from "./pages/SelectionScreen.js";
import Referral from "./pages/Referral.js";
import LanguageContext from "./contexts/languageContext.js";
import selectionContext from "./contexts/selectionContext.js";
import Page404 from "./pages/404page.js";
import Footer from "./components/Footer.js";
import Login from "./pages/Login.js";
import en from "./languages/en.js";
import ma from "./languages/ma.js";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import access from "./accesscode.js";



const App = () => {

  // https://stackoverflow.com/questions/53346462/react-multiple-contexts
  
  // language context settings, for the default start state we use english language
  const [language, setLanguage] = useState(en);
  const languageValue = { language, setLanguage };
  
  // selection list settings. for the default start state we use an empty list []
  const [ currList, updateList ] = useState([]);
  const selectionValue = { currList, updateList };
  
  const [token, setToken] = useState();

  	if(!token || token != access.token) {
    	return <Login setToken={setToken} />
  	}
  	else{

		return (
		  

		    	
		    	<Router>
		    		<LanguageContext.Provider value={languageValue}>
			    		<selectionContext.Provider value={selectionValue}>
			    			<div>
			    				<Header/>

			      				<Switch>

			      					<Route exact path="/">
			      						<SelectionScreen/>
						      		</Route>

						      		<Route exact path="/referral">
						      			<Referral/>
						      		</Route>
						      	
									<Route component={Page404} />
			      	
			      				</Switch>

			      				{/* uncomment line below if you would like to include a footer*/}
		    					{/*<Footer/>*/}

			    			</div>
			    		</selectionContext.Provider>
		    		</LanguageContext.Provider>
		    	</Router>

		    	


		    
		);
	}
};

export default App;
