import React, { useContext, useState } from 'react'
import '../styles/headerstyles.css'
import 'react-dropdown/style.css'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Navigation from "../components/Navigation.js";
import Button from 'react-bootstrap/Button'
import App from "../App.js";
import LanguageContext from "../contexts/languageContext.js";
import en from "../languages/en.js";
import ma from "../languages/ma.js";

import { Link } from "react-router-dom";
// TODO
// MAKE LANGUAGE & CURRENT LIST GLOBAL VARIABLES! LOAD LANGUAGE FOR LANGUAGE OPTION BUTTON!

const Header = () => {

  
  	  
  	  
	  return (
	  <>
	  {/* taken from github https://react-bootstrap.github.io/components/navbar/#home */}
	 
	    <LanguageContext.Consumer>
	    { languageValue => 
		    <Navbar className="d-flex custom_navbar" bg="dark" variant="dark">
		     
		    <Navbar.Brand className="mr-auto p-2">
		      <p id="header-logo-text-paragraph">
		      <img
				alt="NeuroDialect"
				src="./icons/navbar_icon_al.png"
				width="40"
				height="40"
				id="header_img"
		      />{' '}
		      NeuroDialect
		      </p>
		    </Navbar.Brand>
		    <Nav>
			    <NavDropdown style={{padding: "0rem 1.2rem"}} title={languageValue.language.Utilities[1].contents} id="collasible-nav-dropdown">
					<NavDropdown.Item onClick={() => languageValue.setLanguage(en)}>
						<p id="english-language-paragraph">
							<img className="language-image" style={{margin: "5px"}} src="./flags/au.png"/>
							  English
						</p>
					</NavDropdown.Item>
					<NavDropdown.Item onClick={() => languageValue.setLanguage(ma)}>
						<p id="maori-language-paragraph">
							<img className="language-image" style={{margin: "5px"}} src="./flags/ma.png"/>
							  Māori
						</p>
					</NavDropdown.Item>
			    </NavDropdown>
		   
		    </Nav>
		    <Nav>
		    	<Navigation/>
		     
		    </Nav>
		  </Navbar>
	  }
	  </LanguageContext.Consumer>
	   </>
	  )
}

export default Header;
