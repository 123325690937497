import React, { Component, useContext, useState } from 'react'
import '../styles/navbarstyles.css'
import 'react-dropdown/style.css'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import SelectionScreen from "../pages/SelectionScreen.js";
import Referral from "../pages/Referral.js";
import App from "../App.js";
import LanguageContext from "../contexts/languageContext.js";
import en from "../languages/en.js";
import ma from "../languages/ma.js";

import { Link } from "react-router-dom";

/*
	TODO:
	When I need to change the way that we display whether we have forward or back available
	(on the main page you can't go back, on the last you can't go forward)
	we will use this https://stackoverflow.com/questions/38006462/changing-components-based-on-url-with-react-router
	
	Once we have implemented the next page, we will want to do some sort of fancy rendering here to make sure
	that the correct buttons are displayed. TBD

*/
class Navigation extends Component {
	static contextType = LanguageContext;
	constructor() {
	    super();

	    // Define the initial state:
	    this.state = {
	      pagePath: window.location.pathname
	    };
	  }
	  
	  
	  // this method SEEMS superfluous but it is important!
	  // It forces the component to re-render which updates the button text.
	  // Unfortunately, without it, there is no other way to do it!
	  // The value of the pagePath is irrelevant
	  updateState() {
	  	this.setState({pagePath: window.location.pathname});
	  }
	  
	  render() {
	  
	        const language = this.context.language;

		if(window.location.pathname === '/'){
			// the home page, render the referral button
			
			return(
				<div>
				<Link to="/referral"><Button className="navbar-button" variant="success" onClick={() => this.updateState()}>{language.Utilities[2].contents}</Button></Link>
				</div>
			);
		}else if(window.location.pathname === '/referral'){
			// the referral page. Button should take you back to home
			return(
				<div>
				<Link to="/"><Button className="navbar-button" variant="info" onClick={() => this.updateState()}>{language.Utilities[3].contents}</Button></Link>
				</div>
			);
		}else{
			// 404 page, button should just return to home
			return(
				<div>
			 	<Link to="/"><Button className="navbar-button" variant="info" onClick={() => this.updateState()}>{language.Utilities[4].contents}</Button></Link>
			 	</div>
			 );
		}
	  }

}

export default Navigation;
