import React from "react";
import "../styles/footerstyle.css";


/*
	THIS FOOTER IS INCOMPLETE.
	IT CAN BE USED BUT SHOULD BE UPDATED TO A MORE COMPREHENSIVE
	DESIGN IF IT IS USED.
*/

const Footer = () => (
  <footer className="footer">
    <p>
    Developed by Giacomo 2021
    <br/>
    <a target="_blank" href="https://github.com/jocf">github.com/jocf</a>
    </p>
    
  </footer>
);

export default Footer;
