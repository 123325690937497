import React from "react";
import "../styles/referralstyles.css";

const Page404 = () => {
	return(
	<>
		<h2 style={{textAlign: "center"}}>Oops! Page not found!</h2>
	</>
	);


}

export default Page404;

