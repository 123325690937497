import React from "react";
import en from "../languages/en.js";
import ma from "../languages/ma.js";

const LanguageContext = React.createContext({
  language: en,
  setLanguage: () => {}
});

export default LanguageContext;
