import React, { Component, useContext } from "react";
import "../styles/selectscreenstyle.css";
import Header from "../components/Header.js";
import { Modal, Button } from 'react-bootstrap';
import LanguageContext from "../contexts/languageContext";
import selectionContext from "../contexts/selectionContext";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

var currentList = {
	// this keeps track of all the selected modal squares, and the sub squares for each. 
	// this will be used for generating the final pdf. will be looked up in the Text.js document.
	currList: [],
	// list type here is wasteful. this could just be a single variable, but i hate javascript and lists are easy and im lazy
	// this is used to keep track of the sub square that has been selected when a modal box is clicked
	tempSquareList: [],
	
	// add a new element to the currList
	addList: function(newElement, selectionValue)
	{
		
		
		if(currentList.currList.includes(newElement) == false){
			currentList.currList.push(newElement);
		}
		//console.log("Updated by addition...\n");
		//console.log(currentList.currList);
		this.updateSelectionContext(selectionValue);
	},
	
	//remove an element from the currList
	removeList: function(toRemove, selectionValue)
	{
		
		var removalIndex = -1;
		
		for(var i = 0; i < currentList.currList.length; i++){
			if(currentList.currList[i][0] == toRemove){
				removalIndex = i;
				break;
			}
		}
		
		if(removalIndex == -1){
			//console.log("Fake element removal\n");
		}
		else{
			//console.log("Update by removal...\n");
			currentList.currList.splice(removalIndex, 1);
		}
		//console.log(currentList.currList);
		this.updateSelectionContext(selectionValue);
		
	},
	
	// check if the currList contains an element already
	doesContain: function(elementValue)
	{
	
		var checkVariable = false;
		
		for(var i = 0; i < currentList.currList.length; i++){
			if(currentList.currList[i][0] == elementValue){
				checkVariable = true;
			}
		}
		
		return checkVariable;
	},
	
	getFromIndex: function(index)
	{
		// ALWAYS USE DOESCONTAIN WITH THIS METHOD, OTHERWISE RETURNS FALSE.
		
		for(var i = 0; i < currentList.currList.length; i++){
			if(currentList.currList[i][0] == index){
				return currentList.currList[i];
			}
		}
		
		return false;
	},
	
	// reset the squareList (same as delete, we will never want to remove a single element, just reset)
	resetSquareList: function()
	{
		currentList.tempSquareList = [];
	},
	
	// add to the squareList 
	addToSquareList: function(element)
	{
		currentList.tempSquareList.push(element);
	},
	
	// called on ALL operations that mutate the selection list
	updateSelectionContext: function(selectionValue)
	{
		// do the updating here
		//console.log("Attempting to update context... new context is:\n");
		currentList.currList.sort();
		selectionValue.updateList(currentList.currList);
		//console.log(selectionValue);
		
	}

}


class SelectionScreen extends React.Component {
	
    static contextType = LanguageContext;	
    constructor() {
        super()
        this.state = {
            rows: [],
            langMode: 0,
            ModalState: false,
            ModalText: "",
            ModalBody: [],
            ModalIndex: 0,
            
        }
        this.onHide= this.onHide.bind(this);
    }
    
    
    onHide() {
    
    	this.setState({ ModalState: false });
    	this.setState({ ModalText: "" });
    	this.setState({ ModalBody: [] });
    	currentList.resetSquareList();
    
    }

    render() {
        
        return (
        
		<div className="selectScreen">
		{/* Animation stuff, if not happy with can just delete*/}
		
	        <LanguageContext.Consumer>
	        { languageValue =>
	        	<>
	        	<ReactCSSTransitionGroup
					transitionAppear={true}
					transitionAppearTimeout={600}
					transitionEnterTimeout={600}
					transitionLeaveTimeout={200}
					transitionName='SlideIn'
	        	>
		        <div class="grid">
		            <div id ="column-heading-asd" class="column-heading">
		            	<div className="headingText">
		                ASD
		                </div>
		                <img
		                	className="column-heading-icon"
		                    id="diamond_ico"
		                    src="./icons/diamond_white.png"
		                    alt="diamond icon"
		                    
		                ></img>
		            </div>
		            <div id="column-heading-ocd" class="column-heading">
		            	<div className="headingText">
		                OCD
		                </div>
		                <img
		                	className="column-heading-icon"
		                    id="space_ico"
		                    src="./icons/spade_white.png"
		                    alt="spade icon"
		                    
		                ></img>
		            </div>
		            <div id="column-heading-odd" class="column-heading">
		            	<div className="headingText">
		                IDD
		                </div>
		                <img
		                	className="column-heading-icon"
		               	    id="club_ico" 
		                    src="./icons/club_white.png" 
		                    alt="club icon"
		                    
		                ></img>
		            </div>
		            <div id="column-heading-adhd" class="column-heading">
		            	<div className="headingText">
		                ADHD
		                </div>
		                <img
		                	className="column-heading-icon"
		                    id="heart_ico"
		                    src="./icons/heart_white.png"
		                    alt="heart icon"
		                   
		                ></img>
		            </div>
		        </div>
		        <div class="static-grid">
		    		{/*load in grid*/}
		        	{this.rows = this.createSquares(this)}
		        </div>
		        
		        <Modal show={this.state.ModalState} dialogClassName="modalCustom" size="xl" onHide={this.onHide}>
		            <Modal.Header>{this.state.ModalText}</Modal.Header>
		            <Modal.Body>{this.state.ModalBody}</Modal.Body>
		            <Modal.Footer>
		              <selectionContext.Consumer>
		              { selectionValue =>
			      	<Button onClick={() => this.confirmSelections(this, selectionValue)}>{languageValue.language.Utilities[0].contents}</Button>
			      }
			      </selectionContext.Consumer>
		            </Modal.Footer>
		        </Modal>
		        </ReactCSSTransitionGroup>
		      </>
                }
                </LanguageContext.Consumer>
                </div>
                
        );
    }

	// we use callerThis instead of this since 'this' is out of scope in this portion of code
	confirmSelections(callerThis, selectionValue) {
		// set the index into the selected indexes array
		// first we need to check if the square list has any selections.
		// if it does not, then we should not add anything to the list
		// if it DOES, then we should add to the list
		
		currentList.removeList(callerThis.state.ModalIndex, selectionValue);
		
		if(currentList.tempSquareList.length == 0){
			// there are no selections. do nothing
			//console.log("Selection without choosing a square occured\n");
			
		}
		else{
			// create the temp array to be pushed to the list,
			// this should consist of the current modal index, and the selected sub square
			
			let tempArray = [];
			tempArray.push(callerThis.state.ModalIndex);
			tempArray.push(currentList.tempSquareList[0]);
			// this element here is for storing comments in the referral page
			tempArray.push("");
			currentList.addList(tempArray, selectionValue);
		}
		
		// now we should reset the contents of the square list
		currentList.resetSquareList();
		callerThis.setState({ ModalState: false });
	    callerThis.setState({ ModalText: "" });
	    callerThis.setState({ ModalBody: [] });

	}

	squareClickHandler(e, currentState, index, squareType, id){
	 	/*
	 		VALUES FOR squareType:
	 		0 == spade
	 		1 == heart
	 		2 == diamond
	 		3 == club
	 	*/
	 	if(squareType == 0){
	 	
	 		//console.log("Spade square clicked\n");
	 		if(currentList.tempSquareList.length == 0){
	 			//console.log("Adding the square element to temporary list\n");
	 			currentList.addToSquareList(0);
	 		}
	 		else{
	 			//console.log("Removing previous element and adding new square element to temporary list\n");
	 			
	 			
	 			// if the square has already been selected, then unselect and don't add it back.
	 			var toAdd = false;
	 			
	 			if(currentList.tempSquareList[0] != 0){
	 				
	 				toAdd = true;
	 			}
	 			
	 			currentList.resetSquareList();
	 			
	 			if(toAdd){
	 				currentList.addToSquareList(0);
	 			}
	 			
	 		}
	 		//console.log(currentList.tempSquareList);
	 		
	 	}
	 	else if(squareType == 1){
	 		//console.log("Heart square clicked\n");
	 		if(currentList.tempSquareList.length == 0){
	 			//console.log("Adding the heart element to temporary list\n");
	 			currentList.addToSquareList(1);
	 			
	 		}
	 		else{
	 			//console.log("Removing previous element and adding new heart element to temporary list\n");
	 			
	 			
	 			// if the square has already been selected, then unselect and don't add it back.
	 			var toAdd = false;
	 			if(currentList.tempSquareList[0] != 1){
	 				
	 				toAdd = true;
	 			}
	 			
	 			currentList.resetSquareList();
	 			
	 			if(toAdd){
	 				currentList.addToSquareList(1);
	 			}
	 			
	 		}
	 		//console.log(currentList.tempSquareList);
	 	}
	 	else if(squareType == 2){
	 		//console.log("Diamond square clicked\n");
	 		if(currentList.tempSquareList.length == 0){
	 			//console.log("Adding the diamond element to temporary list\n");
	 			currentList.addToSquareList(2);
	 		}
	 		else{
	 			//console.log("Removing previous element and adding new diamond element to temporary list\n");
	 			
	 			
	 			// if the square has already been selected, then unselect and don't add it back.
	 			var toAdd = false;
	 			if(currentList.tempSquareList[0] != 2){
	 				toAdd = true;
	 			}
	 			
	 			currentList.resetSquareList();
	 			if(toAdd){
	 				currentList.addToSquareList(2);
	 			}
	 			
	 		}
	 		//console.log(currentList.tempSquareList);
	 	}
	 	else if(squareType == 3){
	 		//console.log("Club square clicked\n");
	 		if(currentList.tempSquareList.length == 0){
	 			//console.log("Adding the club element to temporary list\n");
	 			currentList.addToSquareList(3);
	 		}
	 		else{
	 			//console.log("Removing previous element and adding new club element to temporary list\n");
	 			
	 			
	 			// if the square has already been selected, then unselect and don't add it back.
	 			var toAdd = false;
	 			if(currentList.tempSquareList[0] != 3){
	 				
	 				toAdd = true;
	 				
	 			}
	 			
	 			currentList.resetSquareList();
	 			if(toAdd){
	 				currentList.addToSquareList(3);
	 			}
	 			
	 		}
	 		//console.log(currentList.tempSquareList);
	 	}
	 	else{
	 		//console.log("The square type sent was not recognized. Please contact the developer\n");
	 	}
		
		//console.log("index=" + index);
		
		
		// reload the body after something has been clicked
		let body = [];
		body.push(
			<div class="modalRow">{this.loadModalBody(id, currentState, index, false)}</div>
		);
		currentState.setState({ ModalBody: body});

	}

	loadModalBody(id, currentState, index, onOpenFlag) {
	    const language = this.context.language;
	    let columns = [];
	    
	    // we need to check if the square we are clicking on already has a selection
	    
	    //boolean to check if a square has already been selected before opening the modal
	    var alreadySelected = false;
	    var selectedSquare = 0;
	    if(onOpenFlag == true){
		    if(currentList.doesContain(index) == true){

		    	// the square has A selection, lets now get that square:
		    	var selectedElement = currentList.getFromIndex(index);
		    	currentList.addToSquareList(selectedElement[1]);
		    	//console.log("A square with square type: " + selectedElement[1] + " has already been selected for this element\n");

		    	
		    }
	    }
	    for (let i = 0; i < language.squares[id - 1].n_subtexts; i++) {
	    	
		if (language.squares[id - 1].icons[i] == "spade") {
		    // load spade div and push to columns
		   
		    // check if the square has been highlighted
		    var classType = "modalColumnSpade";
		    
		    
		    /*
	 		VALUES FOR squareType:
	 		0 == spade
	 		1 == heart
	 		2 == diamond
	 		3 == club
	 	   */
		    
		    // used for switching between white and colored images
		    var image_loc = "./icons/spade_purple.png"

		    if(currentList.tempSquareList.length > 0){
		    	if(currentList.tempSquareList[0] == 0){
		    		classType = "modalColumnSpadeHighlighted";
		    		image_loc = "./icons/spade_white.png"

		    	}
		    }
		    
		    columns.push(
		        <div class={classType} onClick={(e) => { this.squareClickHandler(e, currentState, index, 0, id) }}>
		            <text style={{pointerEvents: "none"}}>{language.squares[id - 1].subtexts[i]}</text>
		            <br></br>
		            <br></br>
		            <br></br>
		            <div className="icon">
		            	<img
		            		className="modal-icon"
		            		id="spade_ico" 
		            		src={image_loc} 
		            		alt="spade icon" 
		            		style={{paddingBottom: "0.3em"}} >
		            	</img>
		            </div>
		        </div>
		    );
		}
		else if (language.squares[id - 1].icons[i] == "heart") {
		    // load heart div and push to columns
		    
		    // check if the square has been highlighted
		    var classType = "modalColumnHeart";
		    
		    
		    /*
	 		VALUES FOR squareType:
	 		0 == spade
	 		1 == heart
	 		2 == diamond
	 		3 == club
	 	   */

	 	    // used for switching between white and colored images
		    var image_loc = "./icons/heart_red.png"
		    
		    if(currentList.tempSquareList.length > 0){
		    	if(currentList.tempSquareList[0] == 1){
		    		classType = "modalColumnHeartHighlighted";
		    		image_loc = "./icons/heart_white.png"
		    	}
		    }
		    
		    columns.push(
		        <div class={classType} onClick={(e) => { this.squareClickHandler(e, currentState, index, 1, id) }}>
		            <text style={{pointerEvents: "none"}}>{language.squares[id - 1].subtexts[i]}</text>
		            <br></br>
		            <br></br>
		            <br></br>
		            <div className="icon">
		           	 <img
		           	 	className="modal-icon"
		           	 	id="heart icon" 
		           	 	src={image_loc}
		           	 	alt="heart icon" 
		           	 	style={{paddingBottom: "0.3em"}} >
		           	 </img>
		            </div>
		        </div>
		    );
		}
		else if (language.squares[id - 1].icons[i] == "diamond") {
		    // load diamond div and push to columns
		    
		     // check if the square has been highlighted
		    var classType = "modalColumnDiamond";
		    
		    
		    /*
	 		VALUES FOR squareType:
	 		0 == spade
	 		1 == heart
	 		2 == diamond
	 		3 == club
	 	   */

	 	    // used for switching between white and colored images
		    var image_loc = "./icons/diamond_blue.png"
		    
		    if(currentList.tempSquareList.length > 0){
		    	if(currentList.tempSquareList[0] == 2){
		    		classType = "modalColumnDiamondHighlighted";
		    		image_loc = "./icons/diamond_white.png" 
		    	}
		    }
		    
		    columns.push(
		        <div class={classType} onClick={(e) => { this.squareClickHandler(e, currentState, index, 2, id) }}>
		            <text style={{pointerEvents: "none"}}>{language.squares[id - 1].subtexts[i]}</text>
		            <br></br>
		            <br></br>
		            <br></br>
		            <div className="icon">
		            	<img 
		            		className="modal-icon"
		            		id="diamond_ico" 
		            		src={image_loc}
		            		alt="diamond icon"
		            		style={{paddingBottom: "0.3em"}} >
		            	</img>
		            </div>
		        </div>
		    );
		}
		else if (language.squares[id - 1].icons[i] == "club") {
		    // load club div and push to columns
		    
		     // check if the square has been highlighted
		    var classType = "modalColumnClub";
		    
		    
		    /*
	 		VALUES FOR squareType:
	 		0 == spade
	 		1 == heart
	 		2 == diamond
	 		3 == club
	 	   */

	 	    // used for switching between white and colored images
		    var image_loc = "./icons/club_green.png"
		    
		    if(currentList.tempSquareList.length > 0){
		    	if(currentList.tempSquareList[0] == 3){
		    		classType = "modalColumnClubHighlighted";
		    		image_loc = "./icons/club_white.png"
		    	}
		    }
		    
		    columns.push(
		        <div class={classType} onClick={(e) => { this.squareClickHandler(e, currentState, index, 3, id) }}>
		            <text style={{pointerEvents: "none"}}>{language.squares[id - 1].subtexts[i]}</text>
		            <br></br>
		            <br></br>
		            <br></br>
		            <div className="icon">
		            	<img 
		            		className="modal-icon"
		            		id="club_ico" 
		            		src={image_loc}
		            		alt="club icon"
		            		style={{paddingBottom: "0.3em"}} >
		            	</img>
		            </div>
		        </div>
		    );
		}
		else {
		    // error log. a square has not loaded properly
		    //console.log("ERROR: A modal body square was not loaded properly\n");
		}
		
	    }
	    //console.log(currentList.tempSquareList);
	    return columns;
	}

	// called from createSquares
	handleModal(e, currentState, index) {
	    const language = this.context.language;
	    /*for each modal, load in the modal properties ONCLICK into the current state. Useful for tracking*/
	    
	    let id = e.target.id;
	    currentState.setState({ ModalState: true });
	    currentState.setState({ ModalIndex: index});
	    //console.log("id=" + id);
	    //console.log("LOG" + language.squares[id - 1].mainText);
	    currentState.setState({ ModalText: language.squares[id - 1].mainText });
	    let body = [];
	    body.push(
		<div class="modalRow">{this.loadModalBody(id, currentState, index, true)}</div>
	    );
	    currentState.setState({ ModalBody: body});
	    
	}



	createSquares(currentState) {
	    const language = this.context.language;
	    let rows = [];
	    for (var i = 0; i < 16; i++) {
		
		    let imgs = this.insertImgs(language.squares[i].icons, language.squares[i].n_icons, false);
		    let curr_index = i;
		    
		    // default background color
		    var gridClassType = "basic-grid";
		    
		    /*
	 		VALUES FOR squareType:
	 		0 == spade
	 		1 == heart
	 		2 == diamond
	 		3 == club
	 	   */
		    
		    for(var j = 0; j < currentList.currList.length; j++){
		    	if(currentList.currList[j][0] == i){
		    		// reload the images to white images
		    		// since we are now a colored square

		    		imgs = []
		    		imgs = imgs = this.insertImgs(language.squares[i].icons, language.squares[i].n_icons, true);

		    		if(currentList.currList[j][1] == 0){
		    			//spade
		    			gridClassType = "spade-grid";
		    		}
		    		else if(currentList.currList[j][1] == 1){
		    			// heart
		    			gridClassType = "heart-grid";
		    		}
		    		else if(currentList.currList[j][1] == 2){
		    			// diamond
		    			gridClassType = "diamond-grid";
		    		}
		    		else if(currentList.currList[j][1] == 3){
		    			// club
		    			gridClassType = "club-grid";
		    		}
		    	}
		    }
		    
		
		    
		    rows.push(
		    	//render in the squares, and here we also call handleModal to update the current state to the modal that is being clicked (see onClick)
		        <div className={gridClassType} id={language.squares[i].position} onClick={(e) => { this.handleModal(e, currentState, curr_index) }}>
		            	{language.squares[i].mainText}
		            <div class="icon">
		                {imgs}
		            </div>
		        </div>
		    );
		
	    }
	    return rows;
	}

	insertImgs(icons, n, whiteFlag) {
	    let imgs = [];
	    // if the whiteFlag == true then we need to load in white
	    // images, otherwise load in coloured images

	    for (var i = 0; i < n; i++) {

		if (icons[i] == "heart") {

			if(whiteFlag){
				imgs.push(
		        <img
		        	className="grid-image"
		        	id="heart_ico" 
		        	style={{paddingBottom: "0.3em"}} 
		        	src="./icons/heart_white.png" 
		        	alt="heart_ico" >
		        </img>
		    	);
			}else{
				imgs.push(
		        <img 
		        	className="grid-image"
		        	id="heart_ico" 
		        	style={{paddingBottom: "0.3em"}} 
		        	src="./icons/heart_red.png" 
		        	alt="heart_ico" >
		        </img>
		    	);
			}
		    
		}
		if (icons[i] == "club") {

			if(whiteFlag){
				imgs.push(
		        <img 
		        	className="grid-image"
		        	id="club_ico" 
		        	style={{paddingBottom: "0.3em"}} 
		        	src="./icons/club_white.png" 
		        	alt="club_ico" >
		        </img>
		    	);
			}else{
				imgs.push(
		        <img 
		        	className="grid-image"
		        	id="club_ico" 
		        	style={{paddingBottom: "0.3em"}} 
		        	src="./icons/club_green.png" 
		        	alt="club_ico" >
		        </img>
		    	);
			}
		    
		}
		if (icons[i] == "spade") {

			if(whiteFlag){
				imgs.push(
		        <img 
		        	className="grid-image"
		        	id="spade_ico" 
		        	style={{paddingBottom: "0.3em"}} 
		        	src="./icons/spade_white.png" 
		        	alt="spade_ico" >
		        </img>
		    	);
			}else{
				imgs.push(
		        <img 
		        	className="grid-image"
		        	id="spade_ico" 
		        	style={{paddingBottom: "0.3em"}} 
		        	src="./icons/spade_purple.png" 
		        	alt="spade_ico" >
		        </img>
		    	);
			}
		}
		if (icons[i] == "diamond") {

			if(whiteFlag){
				imgs.push(
		        <img 
		        	className="grid-image"
		        	id="diamond_ico" 
		        	style={{paddingBottom: "0.3em"}} 
		        	src="./icons/diamond_white.png" 
		        	alt="diamond_ico" >
		        </img>
		    	);
			}else{
				imgs.push(
		        <img 
		        	className="grid-image"
		        	id="diamond_ico" 
		        	style={{paddingBottom: "0.3em"}} 
		        	src="./icons/diamond_blue.png" 
		        	alt="diamond_ico" >
		        </img>
		    	);
			}
		    
		}
	  }
	   
	    return imgs;
	}
}
export default SelectionScreen;
