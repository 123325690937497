import React from "react";
import "../styles/referralstyles.css";
import Button from 'react-bootstrap/Button';
import selectionContext from "../contexts/selectionContext.js";
import LanguageContext from "../contexts/languageContext";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { TextInput } from 'react-native';
import { withStyles } from "@material-ui/core/styles";
import ReactToPrint from "react-to-print";
import { Mailer } from 'nodemailer-react'

/*
TODO:
	
	- potentially add recaptcha for the email form (out of scope currently)

*/

/*

	LIBRARIES USED:
	- Material UI for the text fields
	- React-to-print for the print to pdf functionality
	- Nodemailer for email functionality

*/


// Taken from https://material-ui.com/components/text-fields/
const styles = theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0.7),
    },
  },
});

class Referral extends React.Component {
		//const classes = useStyles();
		
		
		
	constructor() {
		super()
		this.state = {
			// used for storing the comment values
			// map was just easier, I wish I used a map for the 
			// currList in the selection screen :/
			commentsMap: new Map(),
			emailFieldText: "",
			emailErrorField: true
		}
	}
	
	render() {
	 	const { classes } = this.props;
	 	
		return(
			<>
			<LanguageContext.Consumer>
			{ languageValue =>
				<selectionContext.Consumer>
					{ selectionValue =>		
					<>
	
						<div className="referralScreen" ref={(el) => (this.componentRef = el)}>
						<div style={{margin: "0.5rem"}}>
						{/* Animation stuff, if not happy with can just delete*/}
						<ReactCSSTransitionGroup
							transitionAppear={true}
							transitionAppearTimeout={600}
							transitionEnterTimeout={600}
							transitionLeaveTimeout={200}
							transitionName='SlideOut'
						>
						<h3> {languageValue.language.ReferralScreen.referralHeaderText} </h3>
						
						</ReactCSSTransitionGroup>
						<br/>
						
							<>
							{/*className={classes.root}*/}
							<form className={classes.root} noValidate autoComplete="off">
								{this.loadRows(selectionValue, languageValue)}
							</form>
								
							</>

						</div>
						</div>
						{this.loadButton(selectionValue, languageValue)}
					</>
					
					}
				</selectionContext.Consumer>
			}
			</LanguageContext.Consumer>
			</>
		
		);
	}

	sendEmail(){

		// example taken from:
		// https://github.com/mathieutu/nodemailer-react/blob/master/example/index.ts

		const mailerConfig = {
		    defaults: {
		      from: {
		        name: 'NeuroDialect',
		        address: 'neurodialecthost@gmail.com',
		      },
		    },
		    transport: {
		      host: 'smtp.example.com',
		      secure: true,
		      auth: {
		        user: 'neurodialecthost@gmail.com',
		        pass: '',
		      },
		    },
		}

		const emailsList = {
		    pass: Password,
		}

		const mailer = Mailer(mailerConfig, emailsList)

		mailer.send('pass', {
		    firstName: 'Mathieu',
		    lastName: 'Tudisco',
		    brand: 'Linkvalue',
		    newAccount: true,
		    password: Math.random().toString(36).substring(7),
		}, {
		    to: 'foo@bar.fr',
		   	attachments: [{ content: 'bar', filename: 'foo.txt' }],
		})
	}
	
	updateEmailField(newTextValue) {
		// as well as setting the state here, we also need to ensure that
		// the text entered as email text is actually valid. We will modulate 
		// the emailErrorField value here based on whether the email is legitimate or not

		// NOTE: We could have used the inbuilt HTML Type="email" but this wouldn't
		// allow us to use our pretty material UI text boxes.
		// instead we will use a regex taken from here:
		//


		this.setState({emailFieldText: newTextValue})

		let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/

		if(emailRegex.test(newTextValue) == true){
			// email is valid
			this.setState({emailErrorField: false})
		}else{
			// email is invalid
			this.setState({emailErrorField: true})
		}
	
	}
	
	loadEmailField(selectionValue, languageValue){

		if (this.state.emailFieldText == ""){
			return(
				<TextField 
				  id="outlined-basic" 
				  label="Email" 
				  variant="outlined" 
				  value={this.state.emailFieldText}
				  size="small"
				  onChange={(e) => this.updateEmailField(e.target.value)}
				/>
			)
		
		}else if (this.state.emailErrorField == true) {
			return(
				<TextField
				  error
				  id="outlined-error"
				  label="Invalid email address"
				  value={this.state.emailFieldText}
				  variant="outlined"
				  size="small"
				  onChange={(e) => this.updateEmailField(e.target.value)}
				/>
						
			)
		
		}else{
			return(
				<TextField 
				  id="outlined-basic" 
				  label="Email" 
				  variant="outlined" 
				  value={this.state.emailFieldText}
				  size="small"
				  onChange={(e) => this.updateEmailField(e.target.value)}
				/>
			)
		}
	}
	
	loadButton(selectionValue, languageValue) {
		if(selectionValue.currList.length > 0){
			return(
				<div className="referralScreen">
					<ReactCSSTransitionGroup
						transitionAppear={true}
						transitionAppearTimeout={600}
						transitionEnterTimeout={600}
						transitionLeaveTimeout={200}
						transitionName='SlideOut'
					>
					<div>
					<ReactToPrint
						trigger={() => 
							<Button style={{margin: "0.3rem"}} variant="warning">
								{languageValue.language.ReferralScreen.printReferralText}
							</Button>}
						content={() => this.componentRef}
					/>
					</div>
					
					{/*<div>
					{this.loadEmailField(selectionValue, languageValue)}
					<Button style={{margin: "0.3rem"}} variant="warning">
						{languageValue.language.ReferralScreen.emailReferralText}
					</Button>
					</div>
					*/}
					</ReactCSSTransitionGroup>

				</div>
			
			)
		
		}

	}
	
	// key is the square location of the value being accessed from the selectionscreen
	// new value is the new comment text to be added
	// selectionvalue is the selection context
	// index is the position in the actual selectionvalue.currlist that is being changed
	handleCommentsMapUpdate(key, newValue, selectionValue, index) {

		
		// update the state for the referral class
		let tmpMap = this.state.commentsMap
		tmpMap.set(key, newValue)
		this.setState({commentsMap: tmpMap})
		
		// update the selectionvalue context
		let newCurrList = selectionValue.currList
		newCurrList[index][2] = newValue;
		selectionValue.updateList(newCurrList)
	
	}
	
	loadRows(selectionValue, languageValue) {
	
	 	   /*
	 		VALUES FOR squareType:
	 		0 == spade
	 		1 == heart
	 		2 == diamond
	 		3 == club
	 	   */
		
		
				 
		let rows = [];
		// populate the rows to be returned for the referrals page
		for(var i = 0; i < selectionValue.currList.length; i++){
		
			// some temporary key and index values that are used to store the comments
			let tmp_key = selectionValue.currList[i][0]
			let tmp_index = i
		

			// this checks if the map has a comment value already stored for the current symptom being loaded
			
			// if it does not, then we create a new entry for that symptom
			
			if(this.state.commentsMap.has(selectionValue.currList[i][0]) === false){
				// we need to insert into map
				//console.log(this.state.commentsMap)
				this.state.commentsMap.set(tmp_key, selectionValue.currList[i][2])
				
			}

			// for the subposition for subtext, we will use this bit of code:
			
			var subtextPos = 0;
			
			if(selectionValue.currList[i][1] == 0){
				// look for pos of spade
				subtextPos = languageValue.language.squares[selectionValue.currList[i][0]].icons.indexOf("spade");
			} else if(selectionValue.currList[i][1] == 1){
				// look for pos of heart
				subtextPos = languageValue.language.squares[selectionValue.currList[i][0]].icons.indexOf("heart");
			} else if(selectionValue.currList[i][1] == 2){
				// look for pos of diamond
				subtextPos = languageValue.language.squares[selectionValue.currList[i][0]].icons.indexOf("diamond");
			} else{
				// look for pos of club
				subtextPos = languageValue.language.squares[selectionValue.currList[i][0]].icons.indexOf("club");
			}

			// to access language elements corresponding to each symptom,
			// we will need to add 1 to the index (to i), since in my language js files
			// the symptoms are indexed from 1
			
			// we can access the symptom using the language provider using 
			// languageValue.language.squares[selectionValue.currList[i][0]].mainText
			// we can access the symptom description by using 
			// languageValue.language.squares[selectionValue.currList[i][0]].subtexts[subtextPos]
			rows.push(
			<ReactCSSTransitionGroup
				transitionAppear={true}
				transitionAppearTimeout={600}
				transitionEnterTimeout={600}
				transitionLeaveTimeout={200}
				transitionName='SlideOut'
			>
				<div className="symptomDiv">
					
					<p> {languageValue.language.ReferralScreen.symptomText} {i + 1} </p>
					<Grid container style={{textAlign: "center", justifyContent: "center"}} spacing={3}>
					<TextField
					  id="standard-multiline-flexible"
					  label={languageValue.language.ReferralScreen.symptomText}
					  multiline
					  defaultValue={languageValue.language.squares[selectionValue.currList[i][0]].mainText}
					  value={languageValue.language.squares[selectionValue.currList[i][0]].mainText}
					  variant="outlined"
					  style={{width: "30rem", height: "auto"}}
					  InputProps={{
					    readOnly: true,
					  }}
					/>
					
					<TextField
					  id="standard-multiline-flexible"
					  label={languageValue.language.ReferralScreen.symptomDescriptionText}
					  multiline
					  defaultValue={languageValue.language.squares[selectionValue.currList[i][0]].subtexts[subtextPos]}
					  value={languageValue.language.squares[selectionValue.currList[i][0]].subtexts[subtextPos]}
					  variant="outlined"
					  style={{width: "30rem", height: "auto"}}
					  InputProps={{
					    readOnly: true,
					  }}
					/>
					<TextField
					  id="standard-multiline-flexible"
					  label={languageValue.language.ReferralScreen.additionalCommentsText}
					  placeholder={languageValue.language.ReferralScreen.additionalCommentsHelperText}
					  multiline
					  variant="outlined"
					  value={this.state.commentsMap.get(selectionValue.currList[i][0])}
					  onChange={(e) => this.handleCommentsMapUpdate(tmp_key, e.target.value, selectionValue, tmp_index)}
					  style={{width: "30rem", height: "auto"}}
					/>

					</Grid>
					<br/>
					<br/>
				</div>
			</ReactCSSTransitionGroup>
			)
		}
		
		if(selectionValue.currList.length == 0){
			rows.push(
			<ReactCSSTransitionGroup
				transitionAppear={true}
				transitionAppearTimeout={600}
				transitionEnterTimeout={600}
				transitionLeaveTimeout={200}
				transitionName='SlideOut'
			>
			<div>
				<p> {languageValue.language.ReferralScreen.referralErrorText1} </p>
				<p> {languageValue.language.ReferralScreen.referralErrorText2} </p>
				<p> {languageValue.language.ReferralScreen.referralErrorText3} </p>
			</div>
			</ReactCSSTransitionGroup>
			
			)
		}
		
		
		return(rows);
	
	}

}




export default withStyles(styles, { withTheme: true })(Referral);

